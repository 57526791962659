import config from "@/config"
import http from "@/utils/request"

export default {
	voice: {
		url: `${config.API_URL}/getDeviceCallLog/`,
		name: "语音阿里云记录",
		get: async function(id,data){
			return await http.get(this.url+id+"/", data);
		}
	},
    Sms: {
		url: `${config.API_URL}/getDeviceSmsLog/`,
		name: "短信记录",
		get: async function(id,data){
			return await http.get(this.url+id+"/", data);
		}
	},
    pcPush: {
		url: `${config.API_URL}/getDevicePcPushLog/`,
		name: "查询设备PC推送",
		get: async function(id,data){
			return await http.get(this.url+id+"/", data);
		}
	},
    appPush: {
		url: `${config.API_URL}/getDeviceAppPushLog/`,
		name: "查询设备app推送",
		get: async function(id,data){
			return await http.get(this.url+id+"/", data);
		}
	},
    call: {
		url: `${config.API_URL}/getDeviceSpecialCallLog/`,
		name: "查询设备语音专线记录",
		get: async function(id,data){
			return await http.get(this.url+id+"/", data);
		}
	},
}
