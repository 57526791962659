const DEFAULT_CONFIG = {
	//接口地址
	API_URL: "http://192.168.3.8:8007/api/v1",   //郑意辉
	// API_URL: "http://192.168.3.10:8000/api/v1",   //姚洁成

	// API_URL: "http://192.168.3.10:8000/",

	// 静态资源接口
	STATIC_URL:"http://192.168.3.8:8007", //郑意辉
	// STATIC_URL:"http://192.168.3.10:8000", //姚洁成
	// STATIC_URL:"http://192.168.3.8:8005",

	//后台主题
	THEME:"light",
	// THEME:"dark",

	MQTT_CLINET:null
}

// 如果生产模式，就合并动态的APP_CONFIG
// public/config.js
if(process.env.NODE_ENV === 'production'){
	Object.assign(DEFAULT_CONFIG, APP_CONFIG)
}
module.exports = DEFAULT_CONFIG