import config from "@/config"
import http from "@/utils/request"

export default {
    searchCameraList: {
        url: `${config.API_URL}/qxCamera/device/list`,
        name: "查询设备列表",
        get: async function (data){
            return await http.get(this.url, data);
        }
    },
    addCameraDevice: {
        url: `${config.API_URL}/qxCamera/device/add`,
        name: "添加摄像头设备",
        post: async function (data) {
            /** data: 
             * ChipId      string    chipId(必需)，
             * GBID        string    国标(设备编码)(必需),
             * ModelId     string    型号id(必需),
             * VersionId   string    版本id(必需),
             */
            return await http.post(this.url, data);
        },
    },
    searchModelIdList: {
        url: `${config.API_URL}/qxCamera/model/list`,
        name: "查询型号列表",
        get: async function() {
            return await http.get(this.url);
        } 
    },

    searchVeisionList: {
        url: `${config.API_URL}/qxCamera/version/list`,
        name: "查询版本列表",
        get: async function() {
            return await http.get(this.url);
        } 
    }
}
