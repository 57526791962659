<template>
    <div class="home-menu-left">
        <el-menu :default-active="setIndex($route)" class="el-menu-vertical-demo" @open="handleOpen"
            @close="handleClose" :collapse="isCollapse" @select="gotoChile" :unique-opened="true">
            <div class="menu-logo-bigbox">
                <div class="menu-logo">
                    <!-- :src="getURL(entityLogo)" -->
                    <el-image class="menu-logo-img" :src="getURL(entityLogo)" fit="scale-down"
                        style="width: 8vw;height: 6vh;" v-if="entityLogo"></el-image>
                    <div v-else>
                        <div class="title" v-if="langther == 'zh'" style="font-size: 16px; color: #3590f8;">
                            {{ entityName }}
                        </div>
                        <div class="title" v-if="langther == 'en'" style="font-size: 12px; color: #3590f8;">
                            {{ entityName }}
                        </div>
                    </div>
                    <div class="title fl_all_cent" :style="changeSIZE(bigTitle)" v-if="langther == 'zh'">
                        {{ bigTitle }}
                    </div>
                    <div class="title fl_all_cent" :style="changeSIZE(bigTitle)" v-if="langther == 'en'"
                        style="font-size:16px">
                        {{ bigTitle }}
                    </div>

                </div>
            </div>
            <template v-for="cur in routes">
                <el-submenu :index="cur.path" v-if="cur.children && cur.children.length > 0" :key="cur.path">
                    <template slot="title">
                        <i class="iconfont" :class="cur.Meta.icon"></i>
                        <span>{{ cur.Meta.title }}</span>
                    </template>
                    <childrenMenu :chridMenuList="cur.children"></childrenMenu>
                </el-submenu>
                <el-menu-item v-else :index="cur.path" :key="cur.path">
                    <i class="iconfont" :class="cur.Meta.icon"></i>
                    {{ cur.Meta.title }}
                </el-menu-item>

            </template>
        </el-menu>
    </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';

import childrenMenu from "@/components/layout/menu/chridMenu.vue"
export default {
    name: 'menuLeft',
    data() {
        return {
            // isCollapse: this.$store.state.isCollapse,
            routes: [],
            bigTitle: '',
            entityLogo: '',
            entityName: "",
            newPath: '',
            langther: ''
        }
    },
    components: {
        childrenMenu
    },
    computed: {
        ...mapState(['isCollapse']),
        ...mapMutations(['changeMenu'])
    },
    created() {
    },
    mounted() {
        this.langther = localStorage.getItem('theLanguage')

        setTimeout(() => {
            this.bigTitle = JSON.parse(localStorage.getItem("AllTitle")).platform_title
            this.entityName = JSON.parse(localStorage.getItem("AllTitle")).entity_name
        }, 400);

        this.routes = JSON.parse(localStorage.getItem("menu"))     //使用JSON,拿到数据并使用
        // let routes = this.$router.options.routes;
        // console.log(routes[routes.length - 1].children);
        this.bigTitle = ''
        this.entityLogo = JSON.parse(localStorage.getItem("AllTitle")).logo
        this.entityName = ''
    },
    watch: {

    },
    updated() {
    },
    methods: {
        ...mapMutations(['delUserInfo', 'delToken', 'delMenu']),
        /* eslint-disable */
        handleOpen(key, keyPath) {
            // console.log(key, keyPath);
        },
        /* eslint-disable */
        handleClose(key, keyPath) {
            // console.log(key, keyPath);
        },
        setIndex(obj) {
            return obj.path
            // return obj.path+","+obj.name+","+obj.Meta.title+","+obj.Meta.icon
        },
        /* eslint-disable */
        async gotoChile(key, keyPath) {
            console.log(this.newPath);
            let that = this
            this.$emit('getMenuName', key.split(",")[1])
            let ListMenu = JSON.parse(localStorage.getItem("menu"))
            await this.getMeta(ListMenu, key)
            // this.$router.push(key)
            this.$router.replace({
                path: that.newPath[0]
            });
            this.$store.commit("selectMenu", that.newPath);
        },
        // 拼接服务器URL
        getURL(url) {
            return this.$TOOL.getMediaUrl(url)
        },
        changeSIZE(val) {
            if (val.length >= 14 && val.length <= 16) {
                return `font-size:20px`
            } else if (val.length >= 16 && val.length <= 18) {
                return `font-size:18px`
            } else if (val.length >= 18 && val.length <= 20) {
                return `font-size:17px`
            }
        },

        // 拿到meta数据
        async getMeta(list, title) {
            await list.forEach(item => {
                if (item.path == title) {
                    this.newPath = [
                        item.path,
                        item.name,
                        item.Meta.title,
                        item.Meta.icon
                    ]
                    return
                } else {
                    if (item.children) {
                        // 有子
                        this.getMeta(item.children, title)
                    }
                }
            })
        },

    }
}

</script>
<style lang="scss" scoped>
@import '@/assets/scss/menu/menu.scss';
@import '@/assets/scss/flex/flex-all';

// @import '@/assets/scss/elemtntChange/menuLaout.scss';
// @import '@/assets/scss/PublicStyle/view.scss'
::-webkit-scrollbar {
    width: 0px;
    height: 10px;
    background-color: rgb(240, 242, 245);
}
</style>