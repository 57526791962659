<template>
    <div class="homeHeader">
        <!-- <div class="title">{{$t("basic.xiXiangGasPlatform.xiXiang_HomePage")}}</div> -->
        <div class="headerListBox  fl_just_sp-betw">
            <!-- 智慧大屏 -->
            <div class="listBox homeColor flex" @click="goHome">
                <div class="boxIcon fl_all_cent">
                    <i class="iconfont icon-zhongjiqi"></i>
                </div>
                <div class="boxText">
                    <div class="boxTitle fl_align_cen">
                        {{$t("basic.xiXiangGasPlatform.xiXiang_BigSmartScreen")}}
                    </div>
                    <div class="text fl_align_cen">
                        {{$t("basic.xiXiangGasPlatform.xiXiang_DecisionMakingCommandAtAGlance")}}
                    </div>
                </div>
            </div>
            
            <!-- 日常巡检 -->
            <!-- <div class="listBox patrolColor flex" @click="goInspection">
                <div class="boxIcon fl_all_cent">
                    <i class="iconfont icon-ditu_1"></i>
                </div>
                <div class="boxText">
                    <div class="boxTitle fl_align_cen">
                        {{$t("basic.xiXiangGasPlatform.xiXiang_RoutineInspection")}}
                    </div>
                    <div class="text fl_align_cen">
                        <span style="color: aqua;">{{patrolNum}}</span>
                        {{$t("basic.xiXiangGasPlatform.xiXiang_ViewInspectionCompletionRates")}}
                    </div>
                </div>
            </div> -->
            <div class="listBox patrolColor flex" @click="goDevice">
                <div class="boxIcon fl_all_cent">
                    <i class="iconfont icon-ditu_1"></i>
                </div>
                <div class="boxText">
                    <div class="boxTitle fl_align_cen">
                        设备管理
                    </div>
                    <div class="text fl_align_cen">
                        查看设备
                        
                    </div>
                </div>
            </div>
            <!-- 工单管理 -->
            <div class="listBox maintenanceColor flex" @click="goOpeation">
                <div class="boxIcon fl_all_cent">
                    <i class="iconfont icon-baojingmoban"></i>
                </div>
                <div class="boxText">
                    <div class="boxTitle fl_align_cen">
                        {{$t("basic.xiXiangGasPlatform.xiXiang_WorkOrderManagement")}}
                    </div>
                    <div class="text fl_align_cen">
                        {{$t("basic.xiXiangGasPlatform.xiXiang_ViewWorkOrderReporting")}}
                    </div>
                </div>
            </div>
            <!-- 工单上报 -->
            <div class="listBox formColor flex" @click="goWork" style="margin-right: 20px;">
                <div class="boxIcon fl_all_cent">
                    <i class="iconfont icon-chukudan"></i>
                </div>
                <div class="boxText">
                    <div class="boxTitle fl_align_cen">
                        {{$t("basic.xiXiangGasPlatform.xiXiang_OperationAndMaintenanceReport")}}
                    </div>
                    <div class="text fl_align_cen">
                        {{$t("basic.xiXiangGasPlatform.xiXiang_ViewO&MReports")}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default{
    data(){
        return{
           formNum:10,
           patrolNum:'90%',
        }
    },
    methods:{
        goHome(){
            this.$router.push({
                path: "/xixiangGas"
            })
        },
        // 前往巡检巡查
        goInspection(){
            console.log("前往巡检巡查");
            this.$router.push({
                path: "/patrolSystem"
            })
            // this.$store.commit('changeContanName',)
        },
        goDevice(){
            this.$router.push({
                path: "/deviceList"
            })
            // this.$store.commit('changeContanName',)
        },
        // 工单
        goOpeation(){
            console.log(123);
            this.$store.commit('changeContanName','maintain')
            this.$router.push({
                path: "/workOrderManage"
            });
        },
        // 运维
        goWork(){
            this.$router.push({
                path: "/maintainReport"
            });
        }
    }
}

</script>

<style lang='scss' scoped>
.homeHeader{
    width: 100%;
    padding-bottom: 10px;
    box-sizing: border-box;
    .title{
        font-size: 20px;
        font-weight: 600;
        color: #999;
        padding-bottom: 10px;
        box-sizing: border-box;
    }
    .headerListBox{
        width: 100%;
        height: 110px;
        .listBox{
            width: 20%;
            height: 100%;
            box-sizing: border-box;
            padding: 20px;
            border-radius: 0 70px 0 70px;
            box-shadow: 5px 4px 5px 0 rgba(0,0,0,.4);
            cursor: pointer;
                i{
                    font-size: 60px;
                    color: #fff;
                }
            .boxIcon{
                width: 30%;
                height: 100%;
            }
            .boxText{
                width: 70%;
                height: 100%;
                .boxTitle{
                    font-size: 18px;
                    color: #fff;
                    font-weight: 600;
                    width: 100%;
                    height: 50%;
                }
                .text{
                    font-size: 12px;
                    width: 100%;
                    height: 50%;
                }
            }
        }
    }
}

.homeColor{
    background-color: #35DCCA;
}
.patrolColor{
    background-color: #FDAC6C;
}
.maintenanceColor{
    background-color: #FD715C;
}
.formColor{
    background-color: #B2B3FE;
}

</style>