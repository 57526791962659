<template>
    <div style="width: 100%;height: 100%;" v-loading="loading">
        <div :id="aa" style="width: 100%;height: 100%;"></div>
    </div>
    
</template>
<script>
import AMapLoader from '@amap/amap-jsapi-loader';

export default{
    components:{
        // backGround
    },
    props:{
        position:{
            typeof:Object,
            default:function(){
                return{}
            }
        }
    },
    data(){
      return{
        //此处不声明 map 对象，可以直接使用 this.map赋值或者采用非响应式的普通对象来存储。
        map:null,
        AMap:null,
        options : {
            outerStrokeColor: '#00000000',
            outerStrokeWeight: 0,
            outerFillColor: '#03111c',

            outerFillOpacity: 0.9,
            extrusionHeight: 1000,
            wallColor: '#ffffffff',
            wallOpacity:0.5,
            roofColor: '#aa33aabb',
            roofOpacity:0.5,
            extrusionOpacity:0.3,
        },
        gui:null ,
        innerDistricts:null,
        mask:null,
        aa:'',
        loading:true,
       
     } 
 },
 created(){
    this.aa = this.guid()
 },
 mounted(){
    
},
 methods:{
    // 初始化中国地图
    initMap(district,num){
       var that = this
       AMapLoader.load({
            key:"6c9fe0384d628b5222bfa129223c4054",             // 申请好的Web端开发者Key，首次调用 load 时必填
            version:"2.0",      // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
            plugins:['AMap.DistrictSearch','AMap.ControlBar','AMap.ToolBar', 'AMap.Scale','AMap.HawkEye', 'AMap.MapType','AMap.Geolocation', ],       // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        }).then((AMap)=>{
            let viewMode =null
            let pitch = null
            let zoom = null
            let centet = null
            if(num == 2){
                viewMode = "2D"
                pitch = 50
                
            }else{
                viewMode = "3D",
                pitch = 50
               
            }
            if(that.position.lat){
                zoom = 18
                centet=[that.position.lon,that.position.lat]
            }else{
                zoom=5
                centet = [113.87,32.9]
            }
            that.map = new AMap.Map(that.aa,{  //设置地图容器id
                viewMode:viewMode,    //是否为3D地图模式
                mapStyle:"amap://styles/normal",
                zoom:zoom,           //初始化地图级别
                pitch:pitch,
                center:centet, //初始化地图中心点位置
                layers:[],
                rotation: -15,
            });
            // 地图控制3D效果
            that.map.addControl(new AMap.ControlBar({
                showZoomBar:false,
                showControlButton:true,
                position:{
                    left:'20px',
                    top:'20px'
                }
            }))

            //卫星地图
            // that.map.addControl()
            // this.map.addControl(new AMap.Scale())
            // 放大缩小按钮
            this.map.addControl(new AMap.ToolBar({
                position:{
                    left:'130px',
                    top:'35px'
                }
               
            }))
            // 大地图试图选择
            // this.map.addControl(new AMap.HawkEye())

            // 地图类型   卫星图 路网 路况
            this.map.addControl(new AMap.MapType({
                position:{
                    left:"280px",
                    top:"20px"
                }
            }))
            // this.map.addControl(new AMap.Geolocation())
                    // this.auto = new AMap.AutoComplete(this.autoOptions)
            that.getMarke(AMap,that.map,that)
                
            
        
            
        }).catch(e=>{
            console.log(e);
        })

       
    },

    // 这是给地图加上遮罩层
    // addMask(AMap,map,district){
    //     let that = this
    //     new AMap.DistrictSearch({
    //                 extensions:'all',
    //                 subdistrict:0
    //             }).search(district,function(status,result){

    //                 // 外多边形坐标数组和内多边形坐标数组
    //                 var outer = [
    //                     new AMap.LngLat(-360,90,true),
    //                     new AMap.LngLat(-360,-90,true),
    //                     new AMap.LngLat(360,-90,true),
    //                     new AMap.LngLat(360,90,true),
    //                 ];
    //                 var holes = result.districtList[0].boundaries
    //                 var pathArray = [
    //                     outer
    //                 ];
    //                 pathArray.push.apply(pathArray,holes)
    //                 that.mask = new AMap.Polygon( {                 
    //                     path:pathArray,
    //                     strokeColor: that.options.outerStrokeColor,     // 遮罩边界的颜色
    //                     strokeWeight: that.options.outerStrokeWeight,     //遮罩边界的宽度
    //                     fillColor: that.options.outerFillColor,       //遮罩颜色
    //                     fillOpacity: that.options.outerFillOpacity,  //遮罩的透明度
    //                 });

    //                 that.mask.setPath(pathArray);
    //                 map.add(that.mask);

    //                 that.innerDistricts = []
    //                 for (let i=0;i<holes.length;i++){
    //                     let inner = new AMap.Polygon({
    //                         path: holes[i],
    //                         extrusionHeight: that.options.extrusionHeight,   //这是挤出高度
    //                         wallColor: that.options.wallColor,  //挤出高度旁边的墙
    //                         wallOpacity: that.options.wallOpacity,   
    //                         roofColor: that.options.roofColor,     //挤出高度中遮罩颜色
    //                         roofOpacity:that.options.roofOpacity,        //屋顶透明度
    //                         extrusionOpacity:that.options.extrusionOpacity,    //墙的透明度
    //                     });

    //                     map.add(inner);
    //                     that.innerDistricts.push(inner);
    //                 }
    //                 //自动调整视野大小
    //                 // map.setFitView(that.innerDistricts,false,[200,200,200,200]);
    //                 if(that.position.lat){
    //                     console.log();
    //                 }else{
    //                     map.setFitView(that.innerDistricts,false,[0,0,0,0]);
    //                 }
                   
    //             });
    // },
        

    // 获取中心点
    getMarke(AMap,map,that){
        that.loading = false
        var marker = new AMap.Marker({
            // 改地方 that.position.lon, that.position.lat 为 (nan, nan)
            position: new AMap.LngLat(that.position.lon, that.position.lat),
            offset: new AMap.Pixel(-10, -10),
            zoom: 13,
            cursor: 'move'
        })
        marker.setMap(map);
    },

    goMark(){
        let map = this.map;
        map.setCenter([this.position.lat, this.position.lon])  //前往中心点
        map.setZoom(18)
    },

    
    guid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0,
                v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    },

    // 修改地图样式
    changeMapStyle(val){
        console.log(this.map);
        switch(val){
            case 0:{
                // 标准地图
                this.map.setMapStyle('amap://styles/normal')
                break
            }
            case 1:{
                // 幻影黑
                this.map.setMapStyle('amap://styles/dark')
                break
            }
            case 2:{
                // 极夜蓝
                this.map.setMapStyle('amap://styles/darkblue')
                break
            }
            case 3:{
                // 靛青蓝
                this.map.setMapStyle('amap://styles/blue')
                break
            }
            case 4:{
                // 月光银
                this.map.setMapStyle('amap://styles/light')
                break
            }
            case 5:{
                // 雅士灰
                this.map.setMapStyle('amap://styles/grey')
                break
            }
            case 6:{
                // 3D
              this.map=new AMap.Map('container', {
                    pitch:75, // 地图俯仰角度，有效范围 0 度- 83 度
                    viewMode:'3D' // 地图模式
                });
                break
            }
            case 7:{
                // 3D
                this.map.terrain('3D')
                break
            }
            case 8:{
                // 3D
                this.map.terrain('3D')
                break
            }
        }
    }

},


}
</script>
<style  scoped>
    #mapContainer{
        padding:0px;
        margin: 0px;
        width:100%;
        height: 100%;
    }
</style>