import config from "@/config"
import http from "@/utils/request"

export default {
	add: {
		url: `${config.API_URL}/deviceModel/`,
		name: "添加设备型号",
		post: async function(data){
			return await http.post(this.url, data);
		}
	},
    delete: {
		url: `${config.API_URL}/deviceModel/`,
		name: "删除设备型号",
		delete: async function(id,data){
			return await http.delete(this.url+id+"/", data);
		}
	},
    detail: {
		url: `${config.API_URL}/deviceModel/`,
		name: "查询设备型号详情",
		get: async function(id,data){
			return await http.get(this.url+id+"/", data);
		}
	},
    list: {
		url: `${config.API_URL}/deviceModel/`,
		name: "查询设备型号列表",
		get: async function(data){
			return await http.get(this.url, data);
		}
	},
    update: {
		url: `${config.API_URL}/deviceModel/`,
		name: "修改设备型号",
		patch: async function(id,data){
			return await http.patch(this.url+id+"/", data);
		}
	},
	select:{
		url: `${config.API_URL}/searchDeviceModel/`,
		name: "下拉搜索设备型号",
		get: async function(data){
			if(data!=null&&data!=""){
				return await http.get(this.url+data+"/");
			}else{
				return await http.get(this.url);
			}
		}
	}
}