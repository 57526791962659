<template>
    <div class="device-type-stats" style="width: 100%;">
        <div id="blueChartDom" ref="blueDom" style="width: 100%;height:100%;"></div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            devcieData: [],
            devcieOptions: {
                tooltip: {
                    trigger: 'item'
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: 40,
                        fontWeight: 'bold'
                    }
                },
                legend: {
                    type: 'scroll',
                    orient: 'vertical',
                    top: '0%',
                    left: '10%',
                    icon: 'circle',
                    textStyle: {
                        color: '#000'
                    }
                },
                toolbox: {
                    show: false,
                },
                series: [
                    {
                        name: 'Access From',
                        type: 'pie',
                        radius: ['20%', '100%'],
                        avoidLabelOverlap: false,
                        itemStyle: {
                            borderRadius: 10,
                            borderColor: '#fff',
                            borderWidth: 2
                        },
                        label: {
                            show: false,
                            position: 'center'
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: 26,
                                fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: []
                    }
                ]
            },
            charts: null,

            changlightTime: null,

            changeData: 1,
        }
    },
    async mounted() {
        await this.getData()
        this.devcieInit()
       
    },

    methods: {
        devcieInit() {
            if (!this.$refs.blueDom) return
            let that = this;
            // 函数体用于设置  图表的 label  例如  4G燃气报警器 3.33%
            this.devcieOptions.legend.formatter = function (name) {
                let total = 0
                let target
                for (let i = 0; i < that.devcieData.length; i++) {
                    total += that.devcieData[i].value
                    if (that.devcieData[i].name === name) {
                        target = that.devcieData[i].value
                    }
                }
                var arr = [
                    name,
                    ((target / total) * 100).toFixed(2) + '%',
                ]
                
                return arr.join(" ")
            }
            let myChart = that.$echarts.init(that.$refs.blueDom)

            this.devcieOptions.series[0].data = that.devcieData
            myChart.setOption(this.devcieOptions)
        },
        async getData() {
            let res = await this.$API.DataLargeScreen.deviceTypeCount.get();
            this.devcieData = []
            res.data.forEach(item => {
                this.devcieData.push({
                    value: item.count,
                    name: item.name
                })
            })
        }


    }

}
</script>
<style lang="scss" scoped>
.device-type-stats {
    height: 100%;
    width: 100%;
    background-color: rgba(243, 250, 250, .2);
    border-radius: 5px;
    backdrop-filter: blur(1px);
}

#blueChartDom {
    min-width: 300px;
    min-height: 200px;
    height: 100%;
    width: 100%;
}
</style>