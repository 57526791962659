import config from "@/config"
import http from "@/utils/request"

export default {
	list: {
		url: `${config.API_URL}/alarmList/`,
		name: "获取报警记录",
		get: async function(data){
			return await http.get(this.url, data);
		}
	},
	deviceAlarm:{
		url: `${config.API_URL}/deviceAlarm/`,
		name: "获取设备的报警记录",
		get: async function(id,data){
			return await http.get(this.url+id+'/', data);
		}
	},
    update: {
		url: `${config.API_URL}/deviceAlarm/`,
		name: "确认报警记录",
		patch: async function(id,data){
			return await http.patch(this.url+id+'/', data);
		}
	},

	uploadImage: {
		url: `${config.API_URL}/deviceAlarm/`,
		name: "确认报警记录",
		post: async function(id,data){
			return await http.post(this.url+id+'/', data);
		}
	},
   
	alarmReason:{
		url: `${config.API_URL}/alarmType/`,
		name: "报警原因",
		get: async function(data){
			return await http.get(this.url+'/', data);
		}
	},
	untreated:{
		url: `${config.API_URL}/userDeviceAlarmLog/`,
		name: "未处理报警",
		get: async function(data){
			return await http.get(this.url+'/', data);
		}
	},
    // delete: {
	// 	url: `${config.API_URL}/deviceAlarm/`,
	// 	name: "确认报警记录",
	// 	delete: async function(id,data){
	// 		return await http.delete(this.url+id+'/', data);
	// 	}
	// },
	viewAlarmDetailByAlarmId: {
		url: `${config.API_URL}/xiXiang/alarmProcessing/detail`,
		name: "查询报警处理记录详情",
		get: async function(data){
			return await http.get(this.url, data);
		}
	},
}