<template>
    <div style="width:100%;height:100%" v-loading="loading">
        <div style="width:100%;height:100%" v-if="isOver">
            <div class="cellBox">
                <el-button @click="cellCount = 1">1</el-button>
                <el-button @click="cellCount = 4">4</el-button>
                <!-- <el-button @click="cellCount = 6"> 6</el-button> -->
                <el-button @click="cellCount = 9">9</el-button>
                <el-button @click="cellCount = 16">16</el-button>
            </div>
            <div class="allVideo">
                <div :class="cellClass(item)"  v-for="item in cellCount" :key="item">
                    <div v-if="relevanceVideo[item-1]" style="width:100%;height:100%">
                        <videoBox  :url="relevanceVideo[item-1]" style="width:100%;height:100%"></videoBox>
                    </div>
                    <div v-else class="noVidoe flex-center">
                        <i class="iconfont icon-ishipinshixiao" ></i>
                        <div>暂无更多视频</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import  videoBox from "@/components/Public/video.vue"
export default{
    components:{
        videoBox
    },
    props:{
       
        // 所有报警信息
        alarmNews:{
            type:Object
        }
    },
    data(){
        return{
            isOver:false,
            cellCount:4,
            relevanceVideo:[],
            loading:false,
        }
    },
   
    computed:{
        cellClass(){
            return function(){
                switch(this.cellCount){
                    case 1:
                        console.log("成功实现");
                        return ['cell-1']
                    case 4:
                        return ['cell-4']
                    case 6:
                        return ['cell-6']
                    case 9:
                        return ['cell-9']
                    case 16:
                        return ['cell-16']
                    default:
                        break
                }
            }
        }
    },
    async mounted(){
        // 获得所有的
        this.isVideo()
       
    },
    methods:{
        // 摄像头判断
       async isVideo(){
            let name = this.alarmNews.device_model
            let  res
            let pattern = new RegExp("[摄像头]");
            if(pattern.test(name)){
                res = await this.$API.device_camera.play.get(this.alarmNews.device_id,{play:"start" })
                if(res.data){
                    if(window.location.protocol == "http:"){
                         this.relevanceVideo.push(res.data.ws_flv)
                    }else{
                        this.relevanceVideo.push(res.data.wss_flv)
                    }
                   
                    this.loading =false
                }
                // 关联摄像头
                await this.getAllVideo()
            }else{
                await this.getAllVideo()
        }
        },

        // 获得所有关联的摄像头
        async getAllVideo(){
            let that =this
            if(this.alarmNews.CameraUrl.length > 0){
              this.alarmNews.CameraUrl.forEach(async item => {
               this.$API.device_camera.play.get(item,{play:"start" }).then(_res=>{
                    that.loading =false
                     if(_res.data){
                        if(window.location.protocol == "http:"){
                             return this.relevanceVideo.push(_res.data.ws_flv)
                        }else{
                            return this.relevanceVideo.push(_res.data.wss_flv)
                        }
                       
                    }
                   
                  })
                });
            }
            await this.JudageViedoNum()
           
            this.isOver = true
           
        },

        // 判断格子
       JudageViedoNum(){
           let num =  this.relevanceVideo.length
           if(num > 1 || num <3){
               return this.cellCount = 4
           }
           if(num >3 || num <5){
                return this.cellCount = 6
           }
           if(num > 5 || num <8){
            return this.cellCount = 9
           }
           if(num > 8 || num <15){
            return this.cellCount = 16
           }
       },

       

    }
}
</script>
<style lang="scss" scoped>
.cellBox{
    width: 100%;
    height: 7%;
    // display: flex;
}
.allVideo{
    width: 100%;
    height: 92%;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    .cell-1{
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        border: 1px solid #575757;
        font-size:45px ;
        i{
            font-size:100px;
        }
    }
    .cell-4{
        width: 50%;
        height: 50%;
        // zoom:0.5;
        box-sizing: border-box;
        border: 1px solid #575757;
        font-size:35px ;
        i{
            font-size:80px;
        }
    }
    .cell-6{
        width:33.33%;
        height: 33.33%;
        box-sizing: border-box;
        border: 1px solid #575757;
        font-size:25px ;
        i{
            font-size:60px;
        }
    }
    .cell-9{
        width: 33.3%;
        height: 33.3%;
        box-sizing: border-box;
        border: 1px solid #575757;
        font-size:20px ;
        i{
            font-size:45px;
        }

    }
    .cell-16{
        width: 25%;
        height: 25%;
        box-sizing: border-box;
        border: 1px solid #575757;
        font-size:15px ;
        i{
            font-size:40px;
        }
    }
    .noVidoe{
        width:100%;
        height:100%;
        box-sizing: border-box;
        border: 1px solid #575757;
        flex-direction:column;
        // font-size:30px;
        font-weight:600;
    }
    .flex-center{
        display:flex;
        justify-content:center;
        align-items:center;
    }
}
</style>