<template>
    <div>
      <component :is="theme.layoutPattern"></component>
      <el-drawer
        title="后台样式"
        size="290px"
        :visible.sync="$store.state.theme.layoutDrawer"
       >
        <div >
          <layoutSet></layoutSet>
        </div>
      </el-drawer>
    </div>
  </template>
  
  <script>

 import horizontalMenu from './horizontalMenu.vue';
 import verticalMenu from "./verticalMenu.vue"
 import layoutSet from './layoutSet/index.vue';
 import { mapState } from 'vuex'
  export default {
    name: 'HomeMenu',
    components: {
        horizontalMenu,
        verticalMenu,
        layoutSet
    },
    computed:{
      ...mapState(['theme'])
    },
   
    data(){
      return{
        componName:'verticalMenu',
        drawer:true
      }
    },
    methods:{
       
    },
    mounted(){
        // window.document.documentElement.setAttribute( "data-theme", 'light' );
        // routes = this.$router.options.routes
       
    }
  }
  </script>
  <style lang="scss" scoped >
 
 @import "@/assets/scss/theme/handle";
 *{
    padding: 0;
    margin: 0;  
} 

::-webkit-scrollbar {
    width: 0px;
    height: 10px;
    background-color:rgb(240,242,245);
  }
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background-color: rgb(132,133,135);
    // opacity: 0.2;
    // background: fade(@primary-color, 60%);
  }
  ::-webkit-scrollbar-track {

    border-radius: 0;
  }



  </style>