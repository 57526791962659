import Vue from 'vue'
import Vuex from 'vuex'
import childVideo from '@/store/modules/childVideo.js'
import { theme} from "@/store/modules/theme"
import xiXiangGas from "@/store/modules/xiXiangGas.js"
import other from '@/store/modules/other.js'
// vuex持久化
import createPersistedstate  from 'vuex-persistedstate'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    newThemeInfo:'dark', //当前主题
    isCollapse:false,
    token:''   ,    //储存token
    menu:'',   //存储菜单信息
    user_info:{
      username:'超级管理员',
      // loginName:'',
    },   //存储用户信息
    loginName:'',
    
    AllTitle:'',    //大屏Title
    userRole:0,
    tab:{tabsList:[
      // {
      //     path:"/adminHome",
      //     name:"adminHome",
      //     label:"后台首页",
      //     icon:"icon-shouye"
      // }
    ]},
    language:'',
    getAsideList:true,
    admingHome: {},
    smartLargeScreenHeaderData: [
      {
        num: '0',
        symbol: '%',
        title: '设备在线率',
        icon: 'icon-shanghu',
        color: '#35DCCA',
        typeName: "online"
      },
      {
        num: '0',
        symbol: '个',
        icon: 'icon-alarm-full',
        title: '报警总数',
        color: '#FD715C',
        typeName: "alarm"
      },
      {
        num: '0',
        symbol: '个',
        icon: 'icon-changyongtubiao_guzhangfenxi',
        title: '故障设备',
        color: '#FDAC6C',
        typeName: "fault"
      },
      {
        num: '0',
        symbol: '个',
        icon: 'icon-zaixian2',
        title: '已处理',
        color: '#13CF74',
        typeName: "confirmed"
      },
      {
        num: '0',
        symbol: '个',
        icon: 'icon-shanchujinzhi',
        title: '未处理',
        color: '#FD715C',
        typeName: "unconfirmed"
      },
    ],
    // reportTypeData: null
  },
  actions: {
    changeCollapse (context){  //进行侧边栏缩进 展开
      context.commit('CHANGECOLLAPSE')
    }
  },
  mutations: {
    setSmartLargeScreenHeaderData(state, data){
      console.log("setSmartLargeScreenHeaderData", data);
      state.smartLargeScreenHeaderData.forEach( item => {
        for (const key in data) {
          if (item.typeName == key) {
            item.num = data[key];
          }
        }
      });
    },
    // setReportTypeData(state, data){
    //   state.reportTypeData = data;
    // },
    //保存用户角色ID
    setUserRoleId(state,id){
      state.userRole = id
    },

    
    // 修改token,将token存入localStorage
    setToken(state,token){
        state.token = token;
        localStorage.setItem('token',token);
        // localStorage.setItem('')
    },

    // 拿到用户名
    setLoginName(state,loginName){
        state.loginName = loginName;
        localStorage.setItem('loginName',loginName)
    },
    
    // 删除token
    delToken(state){
      state.token = '',
      localStorage.removeItem("token")
    },

    //获得用户信息
    setUserInfo(state,user){
      state.user_info = user;
      localStorage.setItem('user_info',user)
    },
    // 删除用户信息
    delUserInfo(state){
      state.token = '',
      localStorage.removeItem("user_info")
    },

    // 将menu保存在电脑中
    setMenu(state,menu){
      state.menu =menu;
      
      let dataShow = {
        name: "dataShow",
        path: '/dataShow',
        component: '/NewestHomePage/xiXiangGas/components/home/home.vue',
        Meta: {
          title: "数据展示",
          icon: 'icon-icon_yingyongguanli',
        },
      }
      menu.unshift(dataShow)
      localStorage.setItem('menu',JSON.stringify(menu))   //JSON使用修改为字符串,最后修改
      console.log("setMenu" , menu);
    },
    // 拿到大屏名称并保存在本地
    getAllTitle(state,title){
      state.AllTitle = title
      localStorage.setItem('AllTitle',JSON.stringify(title))
    },

    // 删除menu
    delMenu(state){
      state.menu = '',
      localStorage.removeItem("menu")
    },

    //缩进
    CHANGECOLLAPSE(state){
      state.isCollapse = !state.isCollapse
    },
    selectMenu(state,val){
      if(val.constructor == Object){
        if(val.name == 'adminHome'){
          let result=state.tab.tabsList.findIndex(item=>item.name===val.name);
          result===-1 ? state.tab.tabsList.push({
            path:val.path,
            name:val.name,
            label:val.label,
            icon:val.icon
          }) : ''
      }
      }else if(val.constructor == Array){
        if(val[1]!=='adminHome'){
            let result=state.tab.tabsList.findIndex(item=>item.name===val[1]);
            result===-1 ? state.tab.tabsList.push({
              path:val[0],
              name:val[1],
              label:val[2],
              icon:val[3]
            }) : ''
        }
      }
        
    },
    closeTab(state,val){
        let result=state.tab.tabsList.findIndex(item=>item.name===val.name);
        state.tab.tabsList.splice(result,1);
    },

    // 最新侧边栏
    newAsideList(state){
      state.getAsideList = true
    },

    // 取消请求侧边栏
    asideList(state){
      state.getAsideList =false
    },
    // 拿到adminHome
    getAdminHome(state,val){
      state.admingHome = val;
      console.log("val", val);
    }

  },
  getters: {
    getToken(state){
      return state.token || localStorage.getItem("token") || "";
    }
  },
  modules: {
    childVideo,
    theme,
    xiXiangGas,
    other,
  },

  // vuex 数据持久化配置
  plugins:[
    createPersistedstate({
      // 存储方式:localStorage, sessionStorage, cookies
      storage: window.localStorage,
      // 存入本地的 key 值
      key:'theme',
      // 只持久化存储 theme 模块的状态
      paths:['theme']
    })
  ]
})
