<template>
    <div class="homeFootLeftBox" v-loading="loading">
        <!-- 故障概况 -->
        <div class="fontFootLeftBoxTitle">{{$t("basic.xiXiangGasPlatform.xiXiang_Faultprofile")}}</div>
        <div class="header fl_just_sp-around">
            <div class="backColor" v-for="item in numList" :key="item.text">
                <div  class="fl_align_cen devcieNum" style="font-size: 22px;font-weight: 600;">
                    {{ item.num }}
                </div>
                <div style="color: #555; text-align: center;" >
                    {{ $t(item.text) }}
                </div>
            </div>
        </div>
        <div class="echart" id="faultEchart" ref="faultEchart" >

        </div>
    </div>
</template>

<script>
export default{
    data(){
        return{
            loading:true,
            numList:[

                {
                    text:'basic.xiXiangGasPlatform.xiXiang_TotalNumberOfFaults',
                    num:0,
                },
                {
                    text:'basic.xiXiangGasPlatform.xiXiang_Troubleshooting',
                    num:0,
                },
                {
                    text:'basic.xiXiangGasPlatform.xiXiang_UntreatedFaults',
                    num:0,
                },
               
            ],
            options:{
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                    type: 'shadow'
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    top: '10%',
                    bottom: '3%',
                    containLabel: true
                   
                },
                xAxis: [
                    {
                        type: 'category',
                        data: ['111','111','23'],
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLabel:{
                            textStyle:{
                                color:'#000000'
                            }
                        },
                        axisLine:{
                            lineStyle:{
                                color:'#000000',
                            }
                        },
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        axisLabel:{
                            textStyle:{
                                color:'#000000'
                            }
                        },
                        splitLine:{
                            lineStyle:{
                                color:'#ccc'
                            }
                        }
                    }
                ],
                series: [
                    {
                        type: 'bar',
                        barWidth: '60%',
                        color:'#F9C758',
                        data: []
                    }
                ]
            },
            newData:[]
        }
    },
    async mounted(){
        await this.getData()
        this.init()
    },
    methods:{
       async getData(){
            let falutData = await this.$API.xiXiangApi.falutNum.get()
            let type = await this.$API.xiXiangApi.allFaultRecordTypeProportion.get()
           
            let data = falutData.data
            console.log("故障概况type", type);
            this.numList[0].num = data.count
            this.numList[1].num = data.processed
            this.numList[2].num = data.count - data.processed
            
            this.options.xAxis[0].data = []
            this.options.series[0].data = []
            if(type.data && type.data.length){
                type.data.forEach(item => {
                    this.options.xAxis[0].data.push(item.name)
                    this.options.series[0].data.push(item.count)
                });
            }
        },
        init(){
            if(this.$refs.faultEchart) {
                let myChart= this.$echarts.init(this.$refs.faultEchart)
                myChart.setOption(this.options)
            }
            this.loading = false
        }
        
    }
}

</script>

<style lang='scss' scoped>
.homeFootLeftBox{
    width: 49%;
    height: 340px;
    margin-top: 15px;
    box-sizing: border-box;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    .fontFootLeftBoxTitle{
        font-weight: bold;
        font-size: 16px;
        color: black;
        padding: 10px;
        box-sizing: border-box;
    }
    .header{
        width: 100%;
        height: 25%;
        font-size: 16px;
    }
    .echart{
        width: 100%;
        height: 65%;
    }
    .backColor{
        width: 20%;
        height: 80%;
        z-index: 2000;
        font-weight: bold;
        justify-content: space-between;
    }
    .devcieNum{
        width: 100%;
        height: 70%;
        box-sizing: border-box;
        font-size: 16px !important;
        font-weight: 600 !important;
        background-color: #F9C758;
        color: #333;
        border-radius: 10px;
        justify-content: center;
    }
}
</style>