<template>
    <div class="homeContet fl_just_sp-betw">
       <div class="contetBox shadow">
         <div class="fl_just_sp-betw"  style="height: 15%;">
            <!-- 设备概况 -->
            <h4>{{$t("basic.xiXiangGasPlatform.xiXiang_EquipmentOverview")}}</h4>
            <div style="font-size: 11px;" class="fl_align_cen">
            </div>
         </div>
        <div class="handleBox">
            <div style="width: 100%;height: 40%;"  class="flex">
                <div class="backColor" v-for="item in devcienumList" :key="item.text" :style="{width: `calc(100% / ${devcienumList.length})`}">
                    <div  class="fl_align_cen devcieNum">
                        {{ item.num }}
                    </div>
                    <div style="color: #555; font-weight: 600;" >
                        {{ $t(item.test) }}
                    </div>
                </div>
            </div>
            <div style="width: 100%;height: 60%;" class="fl_just_sp-around">
                <div style="width: 25%;height: 100%;" v-for="item in deviceNumber" :key="item.text">
                    <div class="devciePer fl_all_cent" style="margin: auto;">
                        <el-progress type="circle" :percentage="item.num" :width="90" ></el-progress>
                    </div>
                    <div  class="fl_just_cent" style="margin-top: 10px;color: #797979;font-weight: 600;">
                        {{ $t(item.test) }}
                    </div> 
                </div>
            </div>
        </div>
       </div>

       
       <div class="contetBox shadow">
            <div style="height: 15%;">
                <!-- 设备占比 -->
                <h4>
                    {{$t("basic.xiXiangGasPlatform.xiXiang_EquipmentOverview")}}
                </h4>
            </div>
            <div style="height: 85%;">
                <homeRight></homeRight>
            </div>
       </div>
    </div>
</template>

<script>
import homeRight from "./homeContent_right.vue"
export default{
    components:{
        homeRight
    },
    data(){
        return{
            handleNum:10,
           
            devcienumList:[
                {
                    test: ("basic.xiXiangGasPlatform.xiXiang_TotalEquipment"),
                    num:0,
                    name: "count",
                },
                {
                    test: "basic.xiXiangGasPlatform.xiXiang_CallThePoliceToday",
                    num:0,
                    name: "alarm",
                },
                {
                    test: "basic.xiXiangGasPlatform.xiXiang_TroubleshootingToday",
                    num:0,
                    name: "fault",
                },
                {
                    test: "basic.xiXiangGasPlatform.xiXiang_InstallationToday",
                    num:0,
                    name: "install",
                },
                // { 
                //     test: "basic.xiXiangGasPlatform.xiXiang_MaintenanceToday",
                //     num:0,
                // },
            ],

            deviceNumber:[
                {
                    test: "basic.xiXiangGasPlatform.xiXiang_EquipmentOnlineRate",
                    num: 0,
                    color: '',
                },
                {
                    test: "basic.xiXiangGasPlatform.xiXiang_EquipmentOfflineRate",
                    num: 0,
                    color: '',

                },
                {
                    test: "basic.xiXiangGasPlatform.xiXiang_EquipmentFailureRate",
                    num: 0,
                    color: '',

                },
                {
                    test: "basic.xiXiangGasPlatform.xiXiang_EquipmentAlarmRate",
                    num: 0,
                    color: '',

                }
               
            ]
        }
    },
    mounted(){
        this.getAllDevice()
    },
    methods:{
        async getAllDevice(){
            let devcie = await  this.$API.DataLargeScreen.deviceData.get()   //总数
            
            console.log("devcie", this.$API.DataLargeScreen);
            if( devcie.code != 200 ){
                return;
            }
            // 设备数据
            let  deviceData = devcie.data;
            for (let index = 0; index < this.devcienumList.length; index++) {
                for (const key in deviceData) {
                    if (this.devcienumList[index].name == key ) {
                        this.devcienumList[index].num = deviceData[key];
                    }
                }
            }
            console.log("deviceData", deviceData);
            // this.$store.commit("setSmartLargeScreenHeaderData", deviceData);
            // 总数 - 在线 
            this.deviceNumber[0].num = this.keepTwoDecimal((deviceData.online / deviceData.count) * 100);
            this.deviceNumber[1].num = this.keepTwoDecimal(100 - this.deviceNumber[0].num);
            this.deviceNumber[2].num = this.keepTwoDecimal((deviceData.fault / deviceData.count) * 100);
            this.deviceNumber[3].num = this.keepTwoDecimal((deviceData.alarm / deviceData.count) * 100);
        },
        // 四舍五入保留2位小数（若第二位小数为0，则保留一位小数）  
        keepTwoDecimal(num) {  
            var result = parseFloat(num);  
            if (isNaN(result)) {  
            alert('传递参数错误，请检查！');  
            return false;  
            }  
            result = Math.round(num * 100) / 100;
            console.log("result ==>", result);
            return result;  
        },
    }
}

</script>
<style lang='scss' scoped>
.homeContet{
    width: 100%;
    height: 330px;
    .contetBox{
        width: 49%;
        height: 100%;
        box-sizing: border-box;
        padding: 20px 30px 20px 20px;
        border-radius: 10px;
        box-sizing: border-box;
        background-color: #fff;
        .handleBox{
            width: 100%;
            height: 85%;
            box-sizing: border-box;
            padding-left: 20px;
            overflow: auto;
        }
    }
}
.backColor{
    height: 80%;
    z-index: 2000;
    text-align: center;
    font-weight: bold;
    margin-left: 10px;
}
.devcieNum{
    width: 100%;
    height: 60%;
    box-sizing: border-box;
    font-size: 16px !important;
    font-weight: 600 !important;
    background-color: #71E5AC;
    border-radius: 10px;
    color: #333;
    justify-content: center;
}
.devciePer{
    font-size: 25px;
    font-weight: 600;
    color: #8d8d8d;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    box-sizing: border-box;

}

</style>