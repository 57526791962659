<template>
    <div>
         <div class="horizontal_header_Box">
            <div style="width: 10%;">
                <logo></logo>
            </div>
            <div style="width:68%;height:54px;overflow:auto">
                <hotizontalMenChil></hotizontalMenChil>
            </div>
            <div style="width: 22%;">
                <headerLeft></headerLeft>
            </div>
        </div>
        <div class="horizontal_tabMenu">
            <menuBread class="deepTabmenu"></menuBread>
        </div>
        <div class="horizontal_content">
            <router-view></router-view>
        </div>
    </div>
   
</template>
<script>
import headerLeft from "@/components/layout/header/headerLeft.vue"
import hotizontalMenChil from '@/components/layout/horizontal/hotizontalMenChil.vue'
import menuBread from "@/components/layout/menuBread.vue"
import logo from  "@/components/layout/header/logo.vue"
export default{
    data(){
        return{

        }
    },
    components:{
        headerLeft,
        hotizontalMenChil,
        menuBread,
        logo
    },
    methods:{

    }
}

</script>
<style lang="scss" scoped>
.horizontal_header_Box{
    width:100%;
    box-sizing: border-box;
    background-color: #fff;
    border-bottom: 1px solid #eeeded;
    display:flex;
    height:55px ;

}
 .horizontal_tabMenu{
    width: 100%;
    height: 35px;
    width: 100%;
  
}

::v-deep  .menuBreadBox{
    width: 100%;
    height: 35px;
    display: block;
    .mentTabBox{
        width: 100%;
        border-radius: 0;
    }
}

.horizontal_content{
    height:calc(100vh - 55px - 35px);
    width: 100%;
    background-color: rgb(240,242,245);
    padding: 10px ;
    box-sizing: border-box;
}
</style>