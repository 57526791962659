<template>
    <div>
        <template v-for="item in chridMenuList"  >
            <el-submenu  :index="item.path" v-if="item.children && item.children.length > 0" :key="item.path">
                <template slot="title">
                    <i class="iconfont" :class="item.Meta.icon"></i>
                    <span>{{ $t( item.Meta.title ) }}</span>
                </template>
                <!-- 子菜单 -->
                <childrenMenu :chridMenuList="item.children"></childrenMenu>
            </el-submenu>
            <el-menu-item v-else :index="item.path" :key="item.path" >
                <i class="iconfont" :class="item.Meta.icon" style="padding-right: 10px;"></i>
                <span>{{ $t( item.Meta.title ) }}</span>
            </el-menu-item>
        </template>
    </div>
</template>
 
<script>
import childrenMenu from '@/components/layout/menu/chridMenu.vue'
export default{
    data(){
        return{
            
        }
    },
    props:{
        chridMenuList:{
            type:Array,
            default:()=>{
                []
            }
        }
    },
    components:{
        childrenMenu
    },
    methods:{

    }
}

</script>

<style lang='scss' scope>
@import '@/assets/scss/menu/menu.scss';
@import '@/assets/scss/flex/flex-all';
</style>