import axios from "axios";
import qs from 'qs' 
import { Message } from 'element-ui';

import router from '../router'
// import store from '@/store/index'

// axios.defaults.baseURL = 'http://192.168.3.8:8003/'   //郑意辉
// axios.defaults.baseURL = 'http://192.168.3.10:8000/api/v1'   //姚洁成


// HTTP request 拦截器
axios.interceptors.request.use(
	(config) => {
		config.headers['X-Auth-Token'] = `${localStorage.getItem('token')}`
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

// HTTP response 拦截器
axios.interceptors.response.use( 
	(response) => {
		//console.log(response)
 
		return response;
	},
	(error) => {
		console.log(error.response)
		if(error.response){
			if(error.response.status == 404){
				Message.error("Status:404,正在请求不存在的服务器记录！")
			}
			if(error.response.status == 403){
				localStorage.setItem('token','');
				// location.reload();
				router.push("/login");
				return;
			}
			if(error.response.status == 500){
				Message.error("Status:500,服务器发生错误！")
			}
			if(error.response.status == 400){
				Message.error(`Status,${error.response.data.msg}`)
			}
		}

		return Promise.reject(error.response);
	}
);

var http = {
    /** get 请求
     * @param  {接口地址} url 
     * @param  {请求参数} params
     */
    get:(url,params,config)=>{
        return new Promise((resolve,reject)=>{
            axios.get(url,{
                params:params,
                headers:{
                        
                },
				...config
            }).then((res)=>{
                resolve(res.data);
            }).catch((err)=>{
                reject(err)
            })
        })
    },

    /** post 请求
	 * @param  {接口地址} url
	 * @param  {请求参数} params
	 */
    post:(url,params)=>{
        return new Promise((resolve,reject)=>{
            axios.post(url,params,{
                headers:{
					"Content-Type": "multipart/form-data"
				},
            }).then((res)=>{
                resolve(res)
            }).catch((error) =>{
                reject(error)
            })
        })
    },

    /** delete 请求
 	 *  @param  {接口地址} url
	  * @param  {请求参数} params
	 */
    delete:function(url,params){
		return new Promise((resolve, reject) => {
			axios({
				method:'delete',
				url:url,
				headers:{
					"Content-Type": "multipart/form-data"
				},
				data:params
			})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		})
	},

    /** put 请求
 	 *  @param  {接口地址} url
	  * @param  {请求参数} params
	 */
    patch:function(url,params){
		return new Promise((resolve, reject) => {
			axios({
				method:'put',
				url:url,
				headers:{
					"Content-Type": "application/x-www-form-urlencoded"
				},
				transformRequest:[function (data) { return qs.stringify(data) }],
				data:params
			})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		})
	},
	// download(url) {
	// 	axios({
	// 		url: url,
	// 		method: 'get',
	// 		responseType: 'blob',
	// 	}).then(res => {
	// 		const fileName = res.headers.content-disposition.split(';')[1].split('filename=')[1];
	// 		const filestream = res.data;  // 返回的文件流
	// 		// {type: 'application/vnd.ms-excel'}指定对应文件类型为.XLS (.XLS的缩写就为application/vnd.ms-excel)
	// 		const blob = new Blob([filestream], {type: 'application/vnd.ms-excel'});
	// 		const a = document.createElement('a');
	// 		const href = window.URL.createObjectURL(blob); // 创建下载连接
	// 		a.href = href;
	// 		a.download = decodeURL(fileName)
	// 		document.body.appendChild(a);
	// 		a.click();
	// 		document.body.removeChild(a); // 下载完移除元素
	// 		window.URL.revokeObjectURL(href); // 释放掉blob对象
	// 	})
	// }
}
    
    export default http