<template>
    <!-- 西乡燃气平台首先进入该页面 -->
    <div class="homePage">
        <!-- 平台首页 header -->
        <homeHeader></homeHeader>
        <!-- 平台首页 content -->
        <homeContent></homeContent>
        
        <!-- 设备报警趋势 -->
        <homeFoot v-if="false"></homeFoot>

        <!-- 平台首页 footer -->
        <div class="fl_just_sp-betw">
            <!-- 报警总数 已处理报警 未处理报警 -->
            <homeFootLeft></homeFootLeft>
            <!-- 故障总数 已处理故障 未处理故障 -->
            <homeFootRight></homeFootRight>
        </div>
    </div>
</template>

<script>
import homeHeader from './homeHeader.vue';
import homeContent from './homeContent.vue';
import homeFoot from './homeFoot.vue';
import homeFootLeft from './homeFootLeft.vue';
import homeFootRight from './homeFootRight.vue';
export default{
    components:{
        homeHeader,
        homeContent,
        homeFoot,
        homeFootLeft,
        homeFootRight
    },
    data(){
        return{

        }
    },
    methods:{

    }
}

</script>

<style lang='scss' scoped>
.homePage{
    width: 100%;
    height: 100%;
}
.radius{
    border-radius: 0 70px 0 70px;
}
.shadow{
    box-shadow: 3px 2px 5px 0px rgba($color: #000000, $alpha: .4);
}
</style>