import config from "@/config"
import http from "@/utils/request"

export default {
	add: {
		url: `${config.API_URL}/site/`,
		name: "添加场所",
		post: async function(data){
			return await http.post(this.url, data);
		}
	},
    delete: {
		url: `${config.API_URL}/site/`,
		name: "删除场所",
		delete: async function(id,data){
			return await http.delete(this.url+id+"/", data);
		}
	},
    detail: {
		url: `${config.API_URL}/site/`,
		name: "查询场所详情",
		get: async function(id){
			return await http.get(this.url+id+"/");
		}
	},
    list: {
		url: `${config.API_URL}/site/`,
		name: "查询场所列表",
		get: async function(data){
			return await http.get(this.url, data);
		}
	},
    update: {
		url: `${config.API_URL}/site/`,
		name: "修改场所",
		patch: async function(id,data){
			return await http.patch(this.url+id+"/", data);
		}
	},
    select:{
        url: `${config.API_URL}/searchSite/`,
		name: "下拉查询场所列表",
		get: async function(data, params){
			if(data != null && data != ""){
				return await http.get( this.url + data + "/",params);
			}else{
				return await http.get(this.url, params);
			}
		}
    },
    imageList:{
        url: `${config.API_URL}/siteImages/`,
		name: "查看场所图片",
		get: async function(id,data){
			return await http.get(this.url+id+'/',data);
		}
    },
    addImage:{
        url: `${config.API_URL}/siteImage/`,
		name: "添加场所图片",
		post: async function(id,data){
			return await http.post(this.url+id+'/',data);
		}
    },
    deleteImage:{
        url: `${config.API_URL}/siteImages/`,
		name: "删除场所图片",
		delete: async function(id,data){
			return await http.delete(this.url+id+'/',data);
		}
    }
}
