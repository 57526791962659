
<template>
    <div class="homeFootLeftBox">
        <!-- 报警概况 -->
        <div class="alarmTitle">{{$t("basic.xiXiangGasPlatform.xiXiang_AlarmOverview")}}</div>
        <div class="header fl_just_sp-around">
            <div class="backColor" v-for="item in numList" :key="item.text">
                <div  class="fl_align_cen devcieNum" style="font-size: 22px">
                    {{ item.num }}
                </div>
                <div style="color: #555; ; font-weight: 600;" >
                    {{ $t(item.text) }}
                </div>
            </div>
        </div>
        <div class="echart" id="alarmEchart" ref="alarmEchart" >

        </div>
    </div>
</template>

<script>
export default{
    data(){
        return{
            numList:[
                {
                    text: "basic.xiXiangGasPlatform.xiXiang_TotalNumberOfAlarms",
                    num:0,
                },
                {
                    text: "basic.xiXiangGasPlatform.xiXiang_AlarmsProcessed",
                    num:0,
                },
                {
                    text: "basic.xiXiangGasPlatform.xiXiang_UnprocessedAlarms",
                    num:0,
                },
               
            ],
            options:{
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                    type: 'shadow'
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    top: '10%',
                    bottom: '3%',
                    containLabel: true
                   
                },
                xAxis: [
                    {
                        type: 'category',
                        data: ['111','111','23'],
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLabel:{
                            textStyle:{
                                color:'#000000'
                            }
                        },
                        axisLine:{
                            lineStyle:{
                                color:'#000000',
                            }
                        },
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        axisLabel:{
                            textStyle:{
                                color:'#000000'
                            }
                        },
                        splitLine:{
                            lineStyle:{
                                color:'#ccc'
                            }
                        }
                    }
                ],
                series: [
                    {
                        type: 'bar',
                        barWidth: '60%',
                        color:'#EE6666',
                        data: []
                    }
                ]
            },
            newData:[]
        }
    },
    async mounted(){
        await this.getData()
        
        this.$nextTick(()=>{
            this.init()
        })
        
    },
    methods:{
       async getData(){
            let alarmData = await this.$API.xiXiangApi.alarmNum.get()
            // console.log(this.$API.xiXiangApi.alarmNum);
            if( alarmData.code != 200 ){ 
                return
            }
           
            let data = alarmData.data;
            this.numList[0].num =data.count;
            this.numList[1].num =data.processed;
            this.numList[2].num =data.count - data.processed;

            // 报警概况echart图表数据
            let type = await this.$API.xiXiangApi.allAlarmRecordTypeProportion.get();
            console.log("报警概况： ", type);

            this.options.xAxis[0].data = []
            this.options.series[0].data = []
            if(type.data && type.data.length){
                type.data.forEach(item => {
                    this.options.xAxis[0].data.push(item.name)
                    this.options.series[0].data.push(item.count)
                });
            }
        },

        getEchartData(){
            
        },

        init(){
            if(this.$refs.alarmEchart){
                let myChart= this.$echarts.init(this.$refs.alarmEchart);
                myChart.setOption(this.options)
            }
           
        }
        
    }
}

</script>

<style lang='scss' scoped>
.alarmTitle{
    font-size: 16px;
    font-weight: bold;
    padding: 10px 0 10px 10px;
    box-sizing: border-box;
}
.homeFootLeftBox{
    width: 49%;
    height: 340px;
    margin-top: 15px;
    box-sizing: border-box;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    
    .header{
        width: 100%;
        height: 25%;
        font-size: 16px;
    }
    .echart{
        width: 100%;
        height: 65%;
    }
    .backColor{
        width: 20%;
        height: 80%;
        z-index: 2000;
        text-align: center;
        font-weight: bold;
    }
    .devcieNum{
        width: 100%;
        height: 70%;
        box-sizing: border-box;
        font-size: 16px !important;
        font-weight: 600 !important;
        background-color: #EE6666;
        color: #333;
        border-radius: 10px;
        justify-content: center;
    }
}
</style>