import config from "@/config"
import http from "@/utils/request"
export default {
	imageList: {
		url: `${config.API_URL}/deviceImage/`,
		name: "查看设备图片列表",
		get: async function(id,data){
			return await http.get(this.url+id+'/', data);
		}
	},
	addImage:{
		url: `${config.API_URL}/deviceImage/`,
		name: "添加设备图片",
		post: async function(id,data){
			return await http.post(this.url+id+'/', data);
		}
	},
    deleteImage: {
		url: `${config.API_URL}/deviceImage/`,
		name: "删除设备图片",
		delete: async function(id,data){
			return await http.delete(this.url+id+'/', data);
		}
	},
    attrList: {
		url: `${config.API_URL}/deviceAttributeList/`,
		name: "设备属性统计列表",
		get: async function(data){
			return await http.get(this.url, data);
		}
	},
    attrLatest: {
		url: `${config.API_URL}/deviceAttributeLatest/`,
		name: "设备属性最新数据",
		get: async function(data){
			return await http.get(this.url, data);
		}
	},
    uploadAlarm: {
		url: `${config.API_URL}/deviceUploadAlarm/`,
		name: "获取设备上报的报警数据",
		get: async function(id,data){
			return await http.get(this.url+id+'/', data);
		}
	},
    uploadFault: {
		url: `${config.API_URL}/deviceUploadFault/`,
		name: "获取设备上报的故障数据",
		get: async function(id,data){
			return await http.get(this.url+id+'/', data);
		}
	},
    uploadHeart: {
		url: `${config.API_URL}/deviceUploadHeartbeat/`,
		name: "获取设备上报的心跳数据",
		get: async function(id,data){
			return await http.get(this.url+id+'/', data);
		}
	},
	upInstruct:{
		url:`${config.API_URL}/command/`,
		name: "获取指令下发记录",
		get: async function(id,data){
			return await http.get(this.url+id+'/', data);
		}
	},
	bindUser:{
		url:`${config.API_URL}/bindDevicePersonnel/`,
		name: "绑定权利",
		get: async function(id,data){
			return await http.get(this.url+id+'/', data);
		}
	},
	updateEmpower:{
		url:`${config.API_URL}/updateEmpower/`,
		name: "修改用户执行权限",
		post: async function(data){
			return await http.post(this.url, data);
		}
	},
	somkeDetector:{
		url:`${config.API_URL}/device/`,
		name: "433烟感",
		get: async function(id,data){
			return await http.get(this.url+id+'/', data);
		}
	},
	somkeChiledDevice:{
		url:`${config.API_URL}/query433IbmChildDevice/`,
		name: "433烟感主机下面的烟感设备",
		get: async function(id,data){
			return await http.get(this.url+id+'/', data);
		}
	},
	smokerChilerAddr:{
		url:`${config.API_URL}/upload433IbmChildDeviceAddress/`,
		name: "修改用户执行权限",
		post: async function(id,data){
			return await http.post(this.url+id+'/', data);
		}
	},
	
	operatingData:{
		url:`${config.API_URL}/xiXiang/operatingData/list`,
		name: "查询设备上线,下线数据",
		get: async function(data){
			return await http.get(this.url, data);
		}
	},
}