import config from "@/config"
import http from "@/utils/request"

export default {
	bind: {
		url: `${config.API_URL}/Camera433DeviceBind/`,
		name: "设备绑定",
		post: async function(data){
			return await http.post(this.url, data);
		}
	},
	unbind: {
		url: `${config.API_URL}/Camera433DeviceUnbind/`,
		name: "设备绑定",
		post: async function(data){
			return await http.post(this.url, data);
		}
	},
	defense:{
		url: `${config.API_URL}/Camera433DeviceArm/`,
		name: "设备布防",
		post: async function(data){
			return await http.post(this.url, data);
		}
	},
    undefense: {
		url: `${config.API_URL}/Camera433DeviceDisarm/`,
		name: "设备撤防",
		post: async function(data){
			return await http.post(this.url, data);
		}
	},
    setDefense: {
		url: `${config.API_URL}/Camera433DeviceArmNum/`,
		name: "设置防区",
		post: async function(data){
			return await http.post(this.url, data);
		}
	},
    updateMqtt:{
        url: `${config.API_URL}/Camera433DeviceSetMqtt/`,
		name: "更新mqtt",
		post: async function(data){
			return await http.post(this.url, data);
		}
    },
    updateHttp:{
        url: `${config.API_URL}/Camera433DeviceSetHttp/`,
		name: "更新http",
		post: async function(data){
			return await http.post(this.url, data);
		}
    },
    list:{
        url: `${config.API_URL}/getCamera433Sd/`,
		name: "433烟感列表",
		get: async function(id,data){
			return await http.get(this.url+id+'/', data);
		}
    }
}