import config from "@/config"
import http from "@/utils/request"

export default {
	add: {
		url: `${config.API_URL}/accountBasic/`,
		name: "添加用户信息",
		post: async function(data){
			return await http.post(this.url, data);
		}
	},
    delete: {
		url: `${config.API_URL}/accountBasic/`,
		name: "删除用户信息",
		delete: async function(id,data){
			return await http.delete(this.url+id+"/", data);
		}
	},
    detail: {
		url: `${config.API_URL}/accountBasic/`,
		name: "查询用户信息详情",
		get: async function(id,data){
			return await http.get(this.url+id+"/", data);
		}
	},
    list: {
		url: `${config.API_URL}/accountBasic/`,
		name: "查询用户信息列表",
		get: async function(data){
			return await http.get(this.url, data);
		}
	},
    update: {
		url: `${config.API_URL}/accountBasic/`,
		name: "修改用户信息",
		patch: async function(id,data){
			return await http.patch(this.url+id+"/", data);
		}
	},
	loginNews:{
		url: `${config.API_URL}/LoginUserData`,
		name: "查询登录用户数据",
		get: async function(data){
			return await http.get(this.url, data);
		}
	}
}
