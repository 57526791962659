/**
 * @description 自动import导入所有 api 模块
 */

const modules = {}
//登录相关API
const login = require.context('./login', false, /\.js$/)
login.keys().forEach((key) => {
	modules[key.replace(/(\.\/|\.js)/g, '')] = login(key).default
})
//生产相关API
const production = require.context('./production', false, /\.js$/)
production.keys().forEach((key) => {
	modules[key.replace(/(\.\/|\.js)/g, '')] = production(key).default
})
// 设备相关API
const device = require.context('./device', false, /\.js$/)
device.keys().forEach((key) => {
	modules[key.replace(/(\.\/|\.js)/g, '')] = device(key).default
})
// 安装相关的API
const install = require.context('./install', false, /\.js$/)
install.keys().forEach((key) => {
	modules[key.replace(/(\.\/|\.js)/g, '')] = install(key).default
})
//预警规则相关API
const early_warning = require.context('./early_warning', false, /\.js$/)
early_warning.keys().forEach((key) => {
	modules[key.replace(/(\.\/|\.js)/g, '')] = early_warning(key).default
})
//收藏夹相关API
const collection = require.context('./collection', false, /\.js$/)
collection.keys().forEach((key) => {
	modules[key.replace(/(\.\/|\.js)/g, '')] = collection(key).default
})
//用户相关接口
const account = require.context('./account', false, /\.js$/)
account.keys().forEach((key) => {
	modules[key.replace(/(\.\/|\.js)/g, '')] = account(key).default
})
//用户相关接口
const system = require.context('./system', false, /\.js$/)
system.keys().forEach((key) => {
	modules[key.replace(/(\.\/|\.js)/g, '')] = system(key).default
})
//大屏统计相关接口
const HomePage = require.context('./HomePage', false, /\.js$/)
HomePage.keys().forEach((key) => {
	modules[key.replace(/(\.\/|\.js)/g, '')] = HomePage(key).default
})
//大屏统计相关接口
const patrol = require.context('./patrol', false, /\.js$/)
patrol.keys().forEach((key) => {
	modules[key.replace(/(\.\/|\.js)/g, '')] = patrol(key).default
})

//大屏统计相关接口
const cameraProduction = require.context('./cameraProduction', false, /\.js$/)
cameraProduction.keys().forEach((key) => {
	modules[key.replace(/(\.\/|\.js)/g, '')] = cameraProduction(key).default
})

export default modules