<template>
  <div class="device-type-stats">
    
    <!-- 视频播放容器 -->
    <div id="videoContainer" ref="videoContainer" v-loading="loading">
    </div>
    <!-- <div class="topBox" @click="cent_Show = !cent_Show" v-if="false">
          <i class="iconfont icon-shexiangtou"></i>
      </div> -->
    <div class="timeSlider" v-if="showSlider">
      <canvas id="timeline" 
        width="765"
        height="62"
        style="cursor: pointer;border:1px solid #2b2f33;background-color: #2b2f33;"
        ondragstart="return false;"
        @mousewheel.prevent = "mousewheel"
        @mousedown.prevent = "mousedown"
        @mousemove.prevent = "mousemove"
        @mouseup.prevent = "mouseup"
        @mouseout.prevent = "mouseout"
        ></canvas>
    </div>
  </div>
</template>

<script>

export default {
  name: "DemoPlayer",
  component: {
  },
  props: {
    url: { type: String, default: "" },
    // 播放屏幕显示个数
    isShowOperateBtns: {
      type: Number,
      default: () => {
        return 1
      }
    },
    showPTZ: {
      type: Boolean,
      default: () => {
        return true
      }
    },
    devcieID: {},
    HorizonSpeed: {},
    VerticalSpeed: {},
    showSlider: {
      type: Boolean,
      default: () => {
        return false;
      },
    },

  },
  watch: {
    isShowOperateBtns: {
      handler: async function () {
        this.jessibuca.destroy();
        await this.create()
        await this.play()
      }
    },
    url(newValue) {
      console.log("newValue==>", newValue);
      this.newUrl = newValue;
      this.play()
    },
    deep: true,
  },
  data() {
    return {
      jessibuca: null,
      wasm: true,
      vc: "ff",
      playing: true,
      quieting: true,
      loaded: false, // mute
      showOperateBtns: true,
      showBandwidth: false,
      err: "",
      speed: 0,
      performance: "",
      volume: 1,
      rotate: 0,
      vod: true,
      forceNoOffscreen: true,
      buffer: 0.2,
      changeResizeModel: false,
      cent_Show: false,
      loading: false,
      timeout: null,
      newUrl: null,
      timeSlidertimer : null, // 可滑动时间轴
      timeCells: [
        {
          beginTime: new Date().getTime() - 3 * 3600 * 1000,
          endTime: new Date().getTime() - 1 * 3600 * 1000,
          style: {
            background: 'rgba(132, 244, 180, 0.498039)'
          }
        },
        {
          beginTime: new Date().getTime() - 6 * 3600 * 1000,
          endTime: new Date().getTime() - 4 * 3600 * 1000,
          style: {
            background: 'rgba(132, 244, 180, 0.498039)'
          }
        }
      ],
      stampStartTime: 0,
      stampEndTime: 0,
    };
  },
  beforeDestroy() {
    this.destroyVideo()
  },

  async mounted() {
    this.newUrl = this.url
    await this.create();
    window.onerror = (msg) => (this.err = msg);
    var timecell = [
      // {
      //   "beginTime": new Date().getTime() - 3 * 3600 * 1000,
      //   "endTime": new Date().getTime() - 1 * 3600 * 1000,
      //   "style": {
      //     "background": "rgba(132, 244, 180, 0.498039)"
      //   }
      // },
      // {
      //   "beginTime": new Date().getTime() - 6 * 3600 * 1000,
      //   "endTime": new Date().getTime() - 4 * 3600 * 1000,
      //   "style": {
      //     "background": "rgba(132, 244, 180, 0.498039)"
      //   }
      // }
    ];
    
    $("#timeline").TimeSlider({
      init_cells: timecell
    });
    if(this.url){
      this.play()
    }
  },

  unmounted() {
    this.jessibuca.destroy();
    if(this.timeSlidertimer){
      clearInterval(this.timeSlidertimer);
    }
    this.timeSlidertimer = null;
  },
  methods: {
    mousewheel(){
      // console.log("mousewheel");
    },
    mousedown(){
      // console.log("mousedown");
      clearInterval(this.timeSlidertimer);
    },
    mousemove(){
      // console.log("mousemove");
    },
    mouseup(){
      const THIS = this;
      $("#timeline").TimeSlider('returnMouseupTime',null,null,function(time){
        THIS.$emit("changeVideoPlayTime", time);
      });
      
    },
    mouseout(){
      // console.log("mouseout");
    },

    setTimeSliderMiddle(stampStartTime, stampEndTime) {
    this.stampEndTime = stampEndTime;
    this.stampStartTime = stampStartTime;

    // 清除之前残留的延时器
    clearInterval(this.timeSlidertimer);
    this.timeSlidertimer = null;
    
    this.timeSlidertimer = setInterval(() => {
      if (this.stampStartTime >= this.stampEndTime) {
        // 开始时间戳和结束时间戳相等，视频放完了
        clearInterval(this.timeSlidertimer);
        this.timeSlidertimer = null;
        return;
      }
      // $("#timeline").TimeSlider('set_time_to_middle', this.stampStartTime);
      this.stampStartTime += 1000;
      // console.log(this.stampStartTime);
    }, 2000);
  },

    setTitmeSliderTime(time){
      console.log("接收父组件传递下来的时间：", time);
      $("#timeline").TimeSlider('set_time_to_middle', this.timeToTimestamp(time+" 00:00:00"));
    },
    // 时间转换时间戳 
    timeToTimestamp(time){
      let timestamp = Date.parse(new Date(time).toString());
      //timestamp = timestamp / 1000; //时间戳为13位需除1000，时间戳为13位的话不需除1000
      return timestamp;
    },
    // 实例化 jessibuca 插件
    create() {
      let that = this
      let jessibucaDemo = null;

      if (this.isShowOperateBtns == 1) {
        jessibucaDemo = {
          container: this.$refs.videoContainer,
          videoBuffer: 0.2, // 缓存时长
          videoBufferDelay: 1,
          isResize: false,
          text: "",
          useMSE: true,
          useSIMD: false,
          useWCS: false,
          // background: "bg.jpg",
          loadingText: "加载中",
          // hasAudio:false,
          debug: false,
          showBandwidth: that.showOperateBtns, // 显示网速
          showPerformance: false,
          operateBtns: {
            fullscreen: that.showOperateBtns,
            screenshot: that.showOperateBtns,
            play: that.showOperateBtns,
            audio: that.showOperateBtns,
            ptz: that.showPTZ,
            record: that.showOperateBtns
          },
          vod: that.vod,
          forceNoOffscreen: that.forceNoOffscreen,
          isNotMute: false,
          timeout: 5,
          heartTimeoutReplayUseLastFrameShow: true,
          heartTimeout: 10,
          ptzClickType: "mouseDownAndUp",
          ptzZoomShow: false,
          ptzMoreArrowShow: false,
          ptzApertureShow: false,
          ptzFocusShow: false,
          useCanvasRender: false,
          useWebGPU: true,
          demuxUseWorker: true,
          recordType: 'mp4',
        }
      } else {
        jessibucaDemo = {
          container: this.$refs.videoContainer,
          videoBuffer: 0.2, // 设置最大缓冲时长，单位秒，播放器会自动消除延迟。
          videoBufferDelay: 1, // 视频缓冲区延迟
          isResize: true, // 当为true的时候：视频画面做等比缩放后,高或宽对齐canvas区域,画面不被拉伸,但有黑边。
          text: "",
          loadingText: "加载中", //  加载过程中文案。
          useMSE: true, // 否开启MediaSource硬解码
          useSIMD: false,
          useWCS: false, // 否开启Webcodecs硬解码
          showBandwidth: that.showBandwidth, // 显示网速
          showPerformance: false,
          operateBtns: {  // 配置操作按钮 
            fullscreen: that.showOperateBtns, // fullscreen 是否显示全屏按钮
            screenshot: that.showOperateBtns,  // screenshot 是否显示截图按钮
            play: that.showOperateBtns, // play 是否显示播放暂停按钮
            audio: that.showOperateBtns, // audio 是否显示声音按钮
            ptz: that.showPTZ, // 是否显示 上下左右控制器按钮
            // performance:true, // 是否显示性能按钮
            // zoom:true, // 是否显示 放大缩小
            record: that.showOperateBtns, // record 是否显示录制按钮
          },
          // hasAudio:false,
          debug: false,
          vod: that.vod,
          forceNoOffscreen: that.forceNoOffscreen, //是否不使用离屏模式（提升渲染能力）
          isNotMute: false,
          timeout: 5, // 设置超时时长, 单位秒
          heartTimeoutReplayUseLastFrameShow: true,
          heartTimeout: 10, // 设置超时时长, 单位秒
          ptzClickType: "mouseDownAndUp", // 控制器类型: 点击显示
          ptzZoomShow: false, // 视频控制器按钮 左上, 右上, 右下, 左下
          ptzMoreArrowShow: false, // 视频控制器按钮 左上, 右上, 右下, 左下
          ptzApertureShow: false, // 视频控制器按钮 左上, 右上, 右下, 左下
          ptzFocusShow: false, // 视频控制器按钮 左上, 右上, 右下, 左下
          useCanvasRender: false,
          useWebGPU: true,
          demuxUseWorker: true,
          recordType: 'mp4', // 默认录制视频的格式
          RestartNum: 0, // 
          
        }
      }

      this.jessibuca = new window.JessibucaPro(jessibucaDemo);
      var _this = this;

      // 暂停
      this.jessibuca.on("pause", function () {
        _this.playing = false;
        if(_this.timeSlidertimer){
          clearInterval(_this.timeSlidertimer);
        }
      });
      
      //暂停后重新播放
      this.jessibuca.on("play", async function () {
        
        // if(_this.timeSlidertimer){
        //   console.log("on play", "暂停后重新播放", _this.playing);
        //   _this.setTimeSliderMiddle(_this.stampStartTime, _this.stampEndTime);
        // }
        if (!_this.playing) {
          // 接口请求url
          _this.getNewUrl();
        }
        _this.playing = true;

      });

      this.jessibuca.on("websocketError", (ms) => {
        console.log("监听成功!", ms);
      })

      this.jessibuca.on("websocketClose", async () => {
        if (_this.RestartNum < 3) {
          setTimeout(() => {
            _this.jessibuca.play(_this.newUrl)
          }, 1000)
        } else {
          return
        }
      })
      // jessibuca 控制上下左右按钮
      this.jessibuca.on("ptz", function (arrow) {
        console.log(arrow);
        _this.jessibuca.getPTZCmd(arrow)
        _this.$emit('ptz', arrow)

        if (arrow != 'stop') {
          _this.timeout = setInterval(async () => {
            await _this.$API.device.cameraControl.post(_this.devcieID, {
              Command: arrow + '',
              HorizonSpeed: _this.HorizonSpeed,
              VerticalSpeed: _this.VerticalSpeed,
            })
          }, 200);
        } else {
          clearTimeout(_this.timeout)
          _this.$API.device.cameraControl.post(_this.devcieID, {
            Command: 'stop',
            HorizonSpeed: 30,
            VerticalSpeed: 30,
          })
        }

      });


      // 返回卡顿程度
      this.jessibuca.on("performance", function (performance) {
        var show = "卡顿";
        if (performance === 2) {
          show = "非常流畅";
        } else if (performance === 1) {
          show = "流畅";
        }
        _this.performance = show;
      });

    },
    // 播放视频
    play() {
      
      let that = this
      // 播放
      this.jessibuca.on("play", () => {
        that.playing = true;
        that.loaded = true;
        that.quieting = that.jessibuca.quieting;
      });

      // 如果有新的播放地址传进来
      if (this.newUrl) {
        if (this.jessibuca.hasLoaded()) {

          that.jessibuca.play(that.newUrl);
          that.loading = false;
        } else {
          this.jessibuca.on("load", () => {
            that.jessibuca.play(that.newUrl);
            that.loading = false;
          });
        }
      }
    },

    // 触发声音事件
    mute() {
      this.jessibuca.mute();
    },

    // 取消静音
    cancelMute() {
      this.jessibuca.cancelMute();
    },

    // 暂停
    pause() {
      this.jessibuca.pause();
      this.playing = false;
      this.err = "";
      this.performance = "";
    },

    // 设置音量
    volumeChange() {
      this.jessibuca.setVolume(this.volume);
    },

    // 旋转角度
    rotateChange() {
      this.jessibuca.setRotate(this.rotate);
    },

    // 销毁
    destroy() {
      if (this.jessibuca) {
        this.jessibuca.destroy();
      }
      this.create();
      this.playing = false;
      this.loaded = false;
      this.performance = "";
    },

    // 截图
    screenShot() {
      try {
        const imgData = this.jessibuca.screenshot("test", "png", 0.92, 'base64');
        console.log(imgData);
        return imgData
      } catch (e) {
        console.log("截图错误：", e)
        return null
      }
    },
    close() {
      if (this.jessibuca) {
        this.jessibuca.close();
      }
    },

    // 
    changeWasm() {
      this.wasm = this.$refs.wasm.checked;
    },

    restartPlay() {
      this.destroy();
      this.play();
    },

    changeBuffer() {
      this.jessibuca.setBufferTime(Number(this.buffer));
    },

    changeResize() {
      const value = this.changeResizeModel ? 1 : 0;
      this.jessibuca.setScaleMode(value);
    },
    changeVod() {
      const value = this.$refs.vod.checked ? 1 : 0;
      this.jessibuca.setVod(value);
    },
    changeOffscreen() {
      const value = this.forceNoOffscreen ? 1 : 0;
      this.jessibuca.setNoOffscreen(value);
    },

    destroyVideo() {
      this.jessibuca.destroy();
      this.jessibuca = null;
      this.playing = false;
    },

    // 获取新的播放地址
    async getNewUrl() {
      let res = await this.$API.device_camera.play.get(this.devcieID, {
        play: "start"
      })
      console.log(res);
      if (res.data) {
        if (window.location.protocol == "http:") {
          this.newUrl = res.data.ws_flv
        } else {
          this.newUrl = res.data.wss_flv
        }


      }
    }
  },

};
</script>

<style lang="scss" scoped>
.timeSlider {
  width: 100%;
  height: 30px;
}

.device-type-stats {
  height: 100%;
  width: 100%;
  background-color: rgba(243, 250, 250, .2);
  border-radius: 2px;
  backdrop-filter: blur(1px);
  position: relative;
}

#videoContainer {
  //  min-width: 300px;
  //  min-height: 500px;
  height: 100%;
  width: 100%;
}

.topBox {
  width: 70px;
  height: 70px;
  position: absolute;
  right: 0px;
  top: 0px;
  background-color: rgba(68, 157, 240, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  flex-wrap: wrap;
  border-radius: 15px;
  font-size: 18px;

  i {
    font-size: 45px;
  }

}
</style>
