<template>
    <div class="alarmbigbox">
        <div class="header">
            <div class="title">
                设备报警趋势
            </div>
            <div class="btn">
                <el-radio-group v-model="dayNum" size="small" @input="changeOption">
                    <el-radio-button label="7天"></el-radio-button>
                    <el-radio-button label="11天"></el-radio-button>
                </el-radio-group>
            </div>
        </div>
       <div class="char">
            <div id="echarts" class="echarts" ref="echarts" style="width: 100%;height: 100%;">
            </div>
       </div>
        
    </div>
</template>
<script>
export default{
    data(){
        return{
            dayNum:'7天',
            myChart:null,
            option:{
                grid: {
                    left: '4%',
                    right: '4%',
                    bottom: '4%',
                    top:'7%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data: ['2023-4-10', '2023-4-11', '2023-4-12', '2023-4-13', '2023-4-14', '2023-4-15', '2023-4-16'],
                    
                },
                // 鼠标经过拿到数据
                tooltip:{
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                        backgroundColor: '#6a7985'
                        }
                    }
                },
                yAxis: {
                    type: 'value',
                    
                },
                
                series: [
                    {
                    data: [20, 32, 1, 34, 90, 30, 32],
                    type: 'line',
                    smooth: true,
                    symbol: "none", //去掉圆点
                    lineStyle: {
                        color:'rgb(238,102,102)'
                    },
                    areaStyle:{
                        color: {
                            x: 0,
                            y: 1,
                            x2: 0,
                            y2: 0,
                            colorStops: [{
                                offset: 0.4, color: '#ffffff' // 0% 处的颜色
                            }, {
                                offset: 1, color: 'rgb(238,102,102)' // 100% 处的颜色
                            }],
                        }
                    }
                    }
                ]
            },
            changeDay:null,

            date11:[],
            date7:[],
            dataList:[],
        }
    },
    async mounted(){
        await this.getData()
        this.initEcharts()
        // let clearTime = document.getElementById('echarts')
        // clearTime.onmouseover = ()=>{
        //     clearInterval(this.changeDay);
        // }
    },
    methods:{
        initEcharts(){
            this.myChart = this.$echarts.init(this.$refs.echarts)
            // this.myChart.on('globalout',()=>{
            //     this.changeOption()
            // })
            this.changeOption()
        },
        async getData(){
            let Alarmdata = await this.$API.DataLargeScreen.deviceAlarmTrend.get()
            this.dataList=Alarmdata.data
            this.date11=this.$TIME.getTime(11)
            this.date7=this.$TIME.getTime(7)
        },
        changeOption(){
            // clearInterval(this.changeDay);
            if(this.dayNum == '7天'){
                this.option.xAxis.data=this.date7
                let data = []
                this.dataList.slice(0,7).forEach(item=>{
                    data.unshift(item.count)
                })
                this.option.series[0].data =data
            }else if (this.dayNum == '11天') {
                this.option.xAxis.data=this.date11
                let data = []
                this.dataList.slice(0,11).forEach(item=>{
                    data.unshift(item.count)
                })
                this.option.series[0].data =data
            }
            this.myChart.setOption(this.option);
        }
    }
}
</script>
<style lang="scss" scoped>
.alarmbigbox{
    width: 100%;
    height: 300px;
    box-sizing: border-box;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: #fff;
    margin-top: 15px;
    .header{
        width: 100%;
        height: 18%;
        display: flex;
        align-items: center;
        padding: 0 20px;
        .title{
            color:'#000000';
            font-size: 22px;
            font-weight: 600;
        }
        .btn{
            width: 30%;
            padding-left: 300px
        }
    }
    .char{
        width: 100%;
        height: 80%;
         .echarts{
            width:100%;
            height: 100%;
        }
    }
   
}
</style>