import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '@/language'
import routes from './router'
import messageAll from '@/public_Js/messageAll'
import store from '@/store/index'
import nprogress from 'nprogress'
import 'nprogress/nprogress.css'
Vue.use(VueRouter)


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

//保存原型对象的Push
let originPush = VueRouter.prototype.push
let originReplace = VueRouter.prototype.replace
//重写push方法
VueRouter.prototype.push = function (location, res, rej) {
  if (res && rej) {
    originPush.call(this, location, res, rej)
  }
  else {
    originPush.call(this, location, () => { }, () => { })
  }
}
//重写replace方法
VueRouter.prototype.replace = function (location, res, rej) {
  if (res && rej) {
    originReplace.call(this, location, res, rej)
  }
  else {
    originReplace.call(this, location, () => { }, () => { })
  }
}

router.beforeEach(async (to, from, next) => {
      nprogress.start()
      let token = localStorage.getItem('token')
      
      if(to.path === "/login"){
        if(token){
          next({
            path:'/ApplicationCenter'
          })
          return
        }else{
          next();
        }
        return false;
      }


  if (to.path === "/login") {
    if (token) {
      next({
        path: '/ApplicationCenter'
      })
      return
    }
    next();
    return false;
  }


  // 没有token 跳转到login
  if (token == undefined || token == "" || token == null) {
    next({
      path: '/login'
    });
    return false;
  }
  // 暗黑模式是否开启
  if (store.state.theme.isDarkness) {
    let html = document.documentElement
    // 进入不需要暗黑模式的页面
    if (to.meta.dark != undefined && !to.meta.dark) {
      html.classList.remove('dark')
    } else {
      html.classList.add('dark')
    }
  }

  // 跳转生产
  let role = store.state.userRole
  if (!role) {
    role = JSON.parse(localStorage.getItem("AllTitle")).role_id
  }
  if (to.fullPath == "/ProductionSystem") {
    if (to.meta.role[0] != role && to.meta.role[1] != role) {
      messageAll.warningOpen("您没有权限查看!")
      nprogress.done()
      return next('/ApplicationCenter')
    }
  }

  // 解析路由
  if (store.state.getAsideList) {   //close必须let唉router.beforeEach外面,用于解决一直循环
    if (localStorage.getItem('theLanguage') == 'undefined') {
      localStorage.setItem('theLanguage', 'zh')
    }
    // refeRouter()

    //判断是否加载路由
    //接口拿到后端的路由,从原型加载请求文件的方法(一般是登录的时候将路由存到localStore中,然后进行调用)
    let cese = await Vue.prototype.$API.account_basic.loginNews.get({
      language: localStorage.getItem('theLanguage')
    })   

    //后端menu数据赋值 
    let menuList = cese.data.menu;
    // 获取路由文件的路由对象
    let allRouter = router.options.routes;
    
    // 开始正式处理 后端menu数据
    menuList.forEach(async v => {  
      
      // v.children 是接收每个二级路由返回的数组
      v.children = routerChildren(v.children);
      
      //因为后端数据,component有数据就是第三级路由
      if (v.component) {    
        v.component = routerComp(v.component);
      } else {
        //  没有component 的第二级路由
        v.component = { render: (e) => e("router-view") }
      }
      
    })
    const _ = require('lodash');
    // 完整路由,用于给动态添加进 /  中成为二级三级路由，
    allRouter[routes.length - 1].children.push(..._.cloneDeep(menuList))

    console.log("allRouter==>", allRouter);
    //  清空路由缓存(因为我这里跳转都要调用addRoutes,所以我要先清空路由,防止路由重复)
    router.matcher = new VueRouter().matcher;
    //  重新赋值路由(addRoutes会重复添加路由,即使已经存在,虽然可以运行,但是F12会有报错,需要router.Matcher解决)
    router.addRoutes(allRouter)
    let adminhome =  {
        name: "adminHome",
        path: '/adminHome',
        component: '/adminHome/newHome/newHoem.vue',
        Meta: {
          title: i18n.t('basic.menubar.backstageHomePaee'),
          icon: 'icon-shouye',
        },
    }
    
    //  这是系统自带的标签,
    cese.data.menu.unshift(adminhome);
    //将 后端新路由 传给vuex中重新给localstore赋值 
    store.commit('setMenu', cese.data.menu)
    // adminhome给vuex
    store.commit('getAdminHome', adminhome)
    //  close是为了防止陷入循环,上面讲过
    store.commit('asideList')
    
    next({
      ...to,
      replace: true
    })
  }
  next();

});


// 给路由添加路由路径以及路由懒加载的方法 comp：路由路径
function routerComp(comp) {
  // 路由中component的路径
  return (resolve) => require([`@/views/${comp}`], resolve)    //懒加载
  // return () => import(`@/views/${comp}`)    //懒加载
  //  return require(`@/views/${comp}`).default   //非懒加载
}

// 子路由解析 children: 获取后端接口后的每个路由的二级路由
function routerChildren(children) {
  if(children == undefined) return 
  // 便利二级路由
  children.forEach(v => {
    // 没有子路由,直接component赋值
    v.component = routerComp(v.component);
    if (v.children) {
      // 有子路由就递归
      v.children = routerChildren(v.children)
    }
    v.Meta.keepalive = true;
  })
  // 返回
  return children
}

router.afterEach(() => {
  nprogress.done()
})

export default router
